import { createContext, useReducer } from "react";
import LoginReducer from "./LoginReducer";

// Initial state
const initialState = {
  isLoggedIn: false,
  token: "",
  loading: false,
};

// Context
export const LoginStateContext = createContext(initialState);

// Provider component
export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LoginReducer, initialState);

  // Actions
  function loginUser(token) {
    dispatch({
      type: "LOGIN_USER",
      payload: token,
    });
  }

  const logoutUser = () => {
    dispatch({
      type: "LOGOUT_USER",
    });
  };

  const loadingUser = () => {
    dispatch({
      type: "LOADING_USER",
    });
  };

  const finishLoadingUser = () => {
    dispatch({
      type: "FINISH_LOADING_USER",
    });
  };

  return (
    <LoginStateContext.Provider
      value={{
        user: state,
        loginUser,
        logoutUser,
        loadingUser,
        finishLoadingUser,
      }}
    >
      {children}
    </LoginStateContext.Provider>
  );
};
