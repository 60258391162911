import axios from "axios";

export const apiAxios = axios.create({
  baseURL: process.env.BASE_URL,
});

export const setAuthorizationHeader = (token) => {
  const apiToken = `Bearer ${token}`;
  apiAxios.defaults.headers.common["Authorization"] = apiToken;
};

export const removeAuthorizationHeader = () => {
  localStorage.removeItem("token");
  delete apiAxios.defaults.headers.common["Authorization"];
};

// apiAxios.interceptors.request.use((request) => {
//   console.log("Starting Request", request);
//   return request;
// });

// apiAxios.interceptors.response.use((response) => {
//   console.log("Response:", response);
//   return response;
// });

export const validateToken = async (token) => {
  try {
    const res = await apiAxios.get("/auth/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.data.success) {
      return true;
    }
  } catch (error) {
    throw error.response.data;
    // return false;
  }
};
