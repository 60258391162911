import {
  setAuthorizationHeader,
  removeAuthorizationHeader,
} from "../../services/HttpServices";

const LoginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      setAuthorizationHeader(action.payload);
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    case "LOGOUT_USER":
      removeAuthorizationHeader();
      return {
        ...state,
        isLoggedIn: false,
        token: "",
      };
    case "LOADING_USER":
      return {
        ...state,
        loading: true,
      };
    case "FINISH_LOADING_USER":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default LoginReducer;
