import { useEffect, useContext } from "react";
import Router from "next/router";
import { apiAxios } from "../services/HttpServices";
import { useQuery } from "react-query";

import { LoginStateContext } from "../context/Login/LoginState";

async function fetcher() {
  const res = await apiAxios.get("/auth/me");
  return { user: res.data?.data || null };
}

export default function useUser({
  redirectTo = false,
  redirectIfFound = false,
} = {}) {
  const { user: userState } = useContext(LoginStateContext);

  const { status, data } = useQuery(["current-user"], fetcher, {
    enabled: userState.isLoggedIn,
  });

  const user = data?.user;
  const finished = Boolean(data);
  const hasUser = Boolean(user);

  useEffect(() => {
    if (!redirectTo || !finished) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasUser) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasUser)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, finished, hasUser]);

  return { user, status };
}
